import React from "react";

import WebDev from "../assets/icons/web-dev.png";
import AppDev from "../assets/icons/app-dev.png";
import UIUX from "../assets/icons/ui-ux.png";
import DataScience from "../assets/icons/data-science.png";
import DigitalMarketing from "../assets/icons/digital-marketing.png";
import WebDesign from "../assets/icons/web-design.png";
import BlockChain from "../assets/icons/block-chain.png";
import GameDev from "../assets/icons/game-dev.png";
import CCCTaily from "../assets/icons/ccc-taily.png";
import AIML from "../assets/icons/ai-ml.png";
import GameDesign from "../assets/icons/game-design.png";
import PythonDev from "../assets/icons/python-dev.png";
import CJava from "../assets/icons/c-java.png";
import Animation from "../assets/icons/2d-3d.png";
import VideoEditing from "../assets/icons/video-editing.png";
import GraphicDesign from "../assets/icons/graphic-design.png";
import CyberSecurity from "../assets/icons/cyber-security.png";
import FullStackDev from "../assets/icons/full-stack-dev.png";

// Courses Data
const coursesData = [
  {
    image: WebDev,
    name: "Web Development",
    disc: "Master web development with HTML, CSS, JavaScript, and backend technologies for creating dynamic, responsive websites.",
  },
  {
    image: AppDev,
    name: "App Development",
    disc: "Learn app development with expert guidance, mastering Android, iOS, and cross-platform frameworks for impactful mobile apps.",
  },
  {
    image: UIUX,
    name: "UI/UX Design",
    disc: "Design stunning user interfaces and seamless experiences with hands-on training in UI/UX tools and design principles.",
  },
  {
    image: DataScience,
    name: "Data Science",
    disc: "Unlock data insights with expert-led training in Python, machine learning, and AI to excel in data-driven careers.",
  },
  {
    image: DigitalMarketing,
    name: "Digital Marketing",
    disc: "Master SEO, social media, PPC, and analytics with our Digital Marketing course to boost online presence and drive growth.",
  },
  {
    image: WebDesign,
    name: "Web Design",
    disc: "Learn to create visually stunning, user-friendly websites with training in HTML, CSS, and modern web design tools.",
  },
  {
    image: BlockChain,
    name: "BlockChain",
    disc: "Explore blockchain technology, smart contracts, and decentralized apps to build a strong foundation in Web3 development.",
  },
  {
    image: GameDev,
    name: "Game Development",
    disc: "Learn to design and develop immersive games with expert training in Unity, Unreal Engine, and game programming.",
  },
  {
    image: CCCTaily,
    name: "CCC/Taily",
    disc: "Gain essential computer skills with CCC/Tally training, covering accounting, spreadsheets, and data management for career growth.",
  },
  {
    image: AIML,
    name: "AI/ML",
    disc: "Dive into Artificial Intelligence and Machine Learning with hands-on training in algorithms, neural networks, and data analysis.",
  },
  {
    image: GameDesign,
    name: "Game Design",
    disc: "Master game design principles and create engaging interactive experiences with training in storytelling, mechanics, and visuals.",
  },
  {
    image: PythonDev,
    name: "Python Development",
    disc: "Learn Python programming for web development, data analysis, automation, and AI, with hands-on projects and real-world applications.",
  },
  {
    image: CJava,
    name: "C/C++/Java",
    disc: "Master C, C++, and Java programming languages to build efficient software, systems, and applications with hands-on training.",
  },
  {
    image: Animation,
    name: "2D/3D Animation",
    disc: "Learn 2D/3D animation techniques and software to create captivating animations for films, games, and multimedia projects.",
  },
  {
    image: VideoEditing,
    name: "Video Editing",
    disc: "Master video editing techniques using industry-standard software to create professional-grade videos for various media platforms.",
  },
  {
    image: GraphicDesign,
    name: "Graphic Design",
    disc: "Learn graphic design principles and tools to create stunning visuals for branding, advertising, and digital media.",
  },
  {
    image: CyberSecurity,
    name: "Cyber Security",
    disc: "Gain expertise in cybersecurity practices, threat detection, and data protection to safeguard systems and networks from cyber threats.",
  },
  {
    image: FullStackDev,
    name: "Full Stack Dev.",
    disc: "Become a Full Stack Developer with expertise in both frontend and backend technologies to build dynamic, scalable web applications.",
  },
];

const OurCourses = () => {
  return (
    <section className="px-4 xxl:px-20 py-8 xxl:py-20">
      <div>
        <h2 className="text-[30px] md:text-[40px] px-4 xxl:px-20 leading-[22px] md:leading-[44px] text-center font-bold mb-[14px] md:mb-10">
          Our Courses
        </h2>

        <ul className="grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-6 courses">
          {coursesData.map((course, index) => (
            <li
              key={index}
              className="border border-[#DDDDDD] p-[10px] md:p-4 flex flex-col gap-[10px] md:gap-4 rounded-lg"
            >
              <img
                src={course.image}
                alt={course.name}
                className="w-8 md:w-12 h-8 md:h-12"
              />

              <div className="flex flex-col gap-1">
                <h3 className="text-sm md:text-2xl leading-[16px] md:leading-[26.4px] font-bold">
                  {course.name}
                </h3>
                <p className="text-sm md:text-lg font-medium leading-[15px] md:leading-[24px] opacity-60">
                  {course.disc}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
export default OurCourses;
